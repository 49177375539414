<template>
  <b-row>
    <b-modal
      id="modal-delete-danger"
      ok
      cancel
      ok-variant="danger"
      ok-title="Accept"
      modal-class="modal-danger"
      centered
      title="Delete User"
      @ok="deleteUser()"
    >
      <b-card-text> Are you sure you want to delete this user? </b-card-text>
    </b-modal>
    <b-modal
      id="modal-invite-success"
      ok
      cancel
      ok-variant="success"
      ok-title="Accept"
      modal-class="modal-success"
      centered
      title="Accept User"
      @ok="InviteUser()"
    >
      <b-card-text> Are you sure you want to invite this user? </b-card-text>
    </b-modal>
    <b-col cols="12">
      <template>
        <div class="card">
          <!--:sort-by="sortBy"-->
          <h1 class="m-1">Users Subscribed</h1>
          <b-table
            striped
            hover
            responsive
            class="position-relative mb-0"
            :items="users"
            :fields="fields"
          >
            <!-- :current-page="currentPage" -->
            <!-- :per-page="perPage" -->
            <template #cell(actions)="data">
              <div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="flat-danger"
                  class="btn-icon rounded-circle"
                  v-b-tooltip.hover.right
                  title="delete user"
                  @click="popDeleteUserPopUp(data.item.id)"
                >
                  <feather-icon icon="TrashIcon" size="20" />
                </b-button>
                <b-button
                  v-if="!data.item.state"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="flat-success"
                  class="btn-icon rounded-circle"
                  v-b-tooltip.hover.left
                  title="invite user"
                  @click="popInviteUserPopUp(data.item.id)"
                >
                  <feather-icon icon="SendIcon" size="20" />
                </b-button>
              </div>
            </template>
            <template #cell(created_at)="data">
              {{ formattedDate(data.value) }}
            </template>
            <template #cell(state)="data">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="flat-success"
                class="btn-icon rounded-circle"
                v-b-tooltip.hover.top
                title="Confirmed"
                v-if="data.value"
                ><feather-icon icon="CheckCircleIcon" size="20" />
              </b-button>
              <b-button
                v-else
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="flat-warning"
                class="btn-icon rounded-circle"
                v-b-tooltip.hover.bottom
                title="Not Confirmed"
              >
                <feather-icon
                  icon="AlertCircleIcon"
                  variant="flat-success"
                  size="20"
                />
              </b-button>
            </template>
          </b-table>
          <!-- <b-row class="m-1">
            <b-col cols="6">
              <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50"
                  >Per page</label
                >
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                  class="w-25"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="center"
                size="sm"
                class="my-0"
              />
            </b-col>
          </b-row> -->
        </div>
      </template>
    </b-col>
  </b-row>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import axios from "@axios";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import {
  BRow,
  BCol,
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,
  BFormDatepicker,
  BFormCheckbox,
  BSpinner,
  VBTooltip,
  BModal,
  BCardText,
} from "bootstrap-vue";
export default {
  components: {
    BRow,
    BCol,
    BCardCode,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BFormDatepicker,
    BFormCheckbox,
    BSpinner,

    VBTooltip,
    BModal,
    BCardText,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      // value: "",
      // moment,
      // perPage: 10,
      // pageOptions: [10, 25, 50, 100],
      // totalRows: 1,
      // currentPage: 1,
      // sortBy: "released",
      // filter: null,
      // filterOn: [],
      // infoModal: {
      //   id: "info-modal",
      //   title: "",
      //   content: "",
      // },

      fields: [
        {
          key: "index",
          label: "#",
          class: "text-center",
        },
        {
          key: "email",
          label: "email",
          class: "text-center",
        },
        {
          key: "created_at",
          label: "date inscription",
          class: "text-center",
        },
        {
          key: "state",
          label: "status",
          class: "text-center",
        },
        {
          key: "actions",
          label: "actions",
          class: "text-center",
        },
      ],
      users: [],
      token: "",
      beDeleted: null,
      beAccepted: null,
    };
  },
  mounted() {
    this.token = JSON.parse(localStorage.getItem("accessToken"));
    this.getUsers();
  },
  methods: {
    popDeleteUserPopUp(id) {
      this.beDeleted = id;
      this.$root.$emit("bv::show::modal", "modal-delete-danger");
    },
    popInviteUserPopUp(id) {
      this.beAccepted = id;
      this.$root.$emit("bv::show::modal", "modal-invite-success");
    },
    formattedDate(dateStr) {
      const dt = moment(dateStr);
      return dt.format("hh:mm A MM-DD-YYYY");
    },
    getUsers() {
      axios
        .post("/get-invite-users", { token: this.token })
        .then(({ data }) => {
          this.users = this.addIndexToTable(data.message);
        })
        .catch((error) => {
          this.showToast(
            "danger",
            "AlertTriangleIcon",
            "Error",
            "Please try again later."
          );
        });
    },
    deleteUser() {
      axios
        .post("/delete-user", { token: this.token, id_user: this.beDeleted })
        .then(({ data }) => {
          if (data.status === "success") {
            this.getUsers();
            this.showToast("success", "CheckIcon", "Success", data.message);
          } else {
            this.showToast(
              "danger",
              "AlertTriangleIcon",
              "Error",
              data.message
            );
          }
        })
        .catch((error) => {
          this.showToast(
            "danger",
            "AlertTriangleIcon",
            "Error",
            "Please try again later."
          );
        });
    },
    InviteUser() {
      axios
        .post("/send-invite-email", {
          token: this.token,
          id_user: this.beAccepted,
        })
        .then(({ data }) => {
          if (data.status === "success") {
            this.showToast("success", "CheckIcon", "Success", data.message);
          } else {
            this.showToast(
              "danger",
              "AlertTriangleIcon",
              "Error",
              data.message
            );
          }
        })
        .catch((error) => {
          this.showToast(
            "danger",
            "AlertTriangleIcon",
            "Error",
            "Please try again later."
          );
        });
    },
    addIndexToTable(table) {
      const t = table.map((item, index) => {
        item.index = index + 1;
        return item;
      });
      return t;
    },
    showToast(variant, icon, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
